<template>
    <div>
        <st-page :title="title">
            <template #toolbar>
                <InternalDocumentsListToolbar :canSign="true" :showRefreshBtn="true" @refreshInternalDocuments="refreshInternalDocuments"/>
            </template>
            <InternalDocumentsListFilter ref="InternalDocumentsListFilter" :toSign="true" >
                <InternalDocumentsListTable :toSign="true">
                </InternalDocumentsListTable>
            </InternalDocumentsListFilter>
        </st-page>
    </div>
</template>

<script>
import InternalDocumentsListFilter from '@/modules/internal-documents/components/InternalDocumentsListFilter';
import InternalDocumentsListTable from '@/modules/internal-documents/components/InternalDocumentsListTable';
import InternalDocumentsListToolbar from '@/modules/internal-documents/components/InternalDocumentsListToolbar';
import { mapGetters } from "vuex";
import { InternalDocumentsPermissions } from '../internal-documents-permissions';

export default {
    name: 'InternalDocumentsToSignList',
    components: {
        InternalDocumentsListFilter,
        InternalDocumentsListTable,
        InternalDocumentsListToolbar,
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
        }),
        title(){
            return this.$t('INTERNAL_DOCUMENTS.LIST.SIGN_TITLE');
        },
        hasPermissionToReadInternalDocuments() {
            return new InternalDocumentsPermissions(this.currentUser).read;
        },
    },
    methods: {
        refreshInternalDocuments() {
            this.$refs['InternalDocumentsListFilter'].refresh();
        }
    }
}
</script>
